import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { defStates } from '@core/data/constant-data';
import { Registration } from '@core/models/registration';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AssistantCoach } from '@core/models/assistant-coach';

@Component({
  selector: 'app-add-coach',
  templateUrl: './add-coach.component.html',
  styleUrls: ['./add-coach.component.scss'],
})
export class AddCoachComponent implements OnInit {
  form: FormGroup;
  registration: Registration;
  isValidated: boolean = true;
  states = defStates;

  roles = [
    { name: 'Coach', value: 'COACH' },
    { name: 'Administrator', value: 'ADMINISTRATOR' },
    { name: 'Parent', value: 'PARENT' },
    { name: 'Other', value: 'OTHER' },
  ];

  /**
   * Constants
   */
  REQ = 'REQUIRED';
  NOT_REQ = 'NOT_REQUIRED';

  isSubmitting = false;
  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private fb: FormBuilder
  ) {
    let team = this.config.data?.result?.team;
    let existingCoach = this.config.data?.result?.coach;

    this.registration = this.config.data?.result?.registration;
    this.form = this.fb.group({
      id: [null],
      role: [''],
      firstName: [''],
      lastName: [''],
      email: [''],
      phone: [''],
      coachAddress: [''],
      coachCity: [''],
      coachState: [''],
      coachFacebook: [''],
      coachTwitter: [''],
      coachInstagram: [''],
      additionalCoachInfo: this.registration?.additionalCoachInfo,
      team: [team],
    });

    if (existingCoach) {
      this.setExistingCoachData(existingCoach);
    }

    Object.entries(this.registration).find(([key, value]) => {
      if (value == 'REQUIRED') {
        this.addValidator(key);
      }

      this.addValidator('firstName');
      this.addValidator('lastName');
      this.addValidator('email');
      this.addValidator('phone');
    });
  }

  setExistingCoachData(coach: any): void {
    this.form.patchValue({
      id: coach?.id,
      role: coach?.role,
      firstName: coach?.firstName,
      lastName: coach?.lastName,
      email: coach?.email,
      phone: coach?.phoneNumber,
      coachAddress: coach?.address,
      coachCity: coach?.city,
      coachState: coach?.state,
      coachFacebook: coach?.facebookPage,
      coachTwitter: coach?.twitterHandle,
      coachInstagram: coach?.instagramHandle,
      team: coach?.team,
    });
  }

  addValidator(formControlName: string): void {
    this.form.controls[formControlName]?.setValidators([Validators.required]);
    this.form.controls[formControlName]?.updateValueAndValidity();
  }

  ngOnInit(): void {}

  generateFormGrp(formCtrl) {
    this.form.addControl(`coach_${formCtrl}`, this.fb.control(''));
    return `coach_${formCtrl}`;
  }

  cancel(): void {
    this.ref.close();
  }

  submit(): void {
    if (this.isSubmitting) {
      return;
    }
    this.isSubmitting = true;

    let coachForm = this.form.value;

    if (!this.form.valid) {
      this.isValidated = false;
      this.isSubmitting = false;
      return;
    }

    let coachData: AssistantCoach = {
      role: coachForm?.role,
      firstName: coachForm?.firstName,
      lastName: coachForm?.lastName,
      email: coachForm?.email,
      phoneNumber: coachForm?.phone,
      address: coachForm?.coachAddress,
      city: coachForm?.coachCity,
      state: coachForm?.coachState,
      facebookPage: coachForm?.coachFacebook,
      twitterHandle: coachForm?.coachTwitter,
      instagramHandle: coachForm?.coachInstagram,
      team: coachForm?.team,
      additionalCoachInfo: [],
    };

    this.ref.close(coachData);
    this.isSubmitting = false;
  }
}
