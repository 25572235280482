<div class="registration-container">
  <div class="top-header-container">
    <div class="p-grid" style="background-color: #f6f8fa">
      <div class="p-col-12">
        <app-public-header></app-public-header>
      </div>
    </div>

    <div class="public-subcontainer">
      <div class="p-col-12 p-xl-9 p-lg-9 p-xs-12">
        <div
          class="detail-header"
          [style.backgroundColor]="primaryColor ? primaryColor : '#a90000'"
        >
          <div class="tournament-panel">
            <div class="tourney-image" *ngIf="tourney?.photo; else noPhoto">
              <img class="image" [src]="logoUrl" />
            </div>
            <ng-template class="tourney-image" #noPhoto>
              <img
                class="image"
                style="width: 90%"
                src="https://dynamic.brandcrowd.com/template/preview/design/81e759b4-3042-4ad6-948f-9806102859e8/4716649d-626c-4500-a8aa-8a2860c86d5f?v=4&size=extra-large"
              />
            </ng-template>
          </div>

          <div style="display: inline-block">
            <div class="tournament-panel">
              <div>
                <div class="tournament-name">{{ tourney?.name }}</div>
                <div class="org-name">{{ tourney?.organization?.name }}</div>
              </div>
              <div>
                <div class="tournament-name">Registration</div>
              </div>
            </div>

            <div class="date-panel">
              <div class="date-item">{{ tournamentDate }}</div>
              <div class="date-item">
                {{ tourney?.city }}, {{ tourney?.state }}
              </div>
            </div>

            <div class="nav-button-panel">
              <button
                pButton
                pRipple
                icon="pi pi-info"
                iconPos="left"
                type="button"
                label="Team(s)"
                class="p-button-outlined my-1"
                class="btn-navigator"
                (click)="scroll(teamInfo)"
              ></button>
              <button
                pButton
                icon="pi pi-sitemap"
                iconPos="left"
                pRipple
                type="button"
                label="Coaches"
                class="p-button-outlined p-button-danger my-1 links-font"
                class="btn-navigator"
                (click)="scroll(coachInfo)"
              ></button>
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-map"
                iconPos="left"
                label="Players"
                class="p-button-outlined p-button-danger p-col-12 my-1"
                class="btn-navigator"
                (click)="scroll(playerInfo)"
              ></button>
              <button
                *ngIf="registration?.requirePayment != 'NOT_REQ'"
                pButton
                pRipple
                type="button"
                icon="pi pi-calendar"
                iconPos="left"
                label="Payment"
                class="p-button-outlined p-button-danger p-col-12 my-1"
                class="btn-navigator"
                (click)="scroll(payment)"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <form [formGroup]="form">
    <div class="main-container">
      <div class="public-subcontainer">
        <div class="p-col-12 p-xl-9 p-lg-9 p-xs-12">
          <!-- <div *ngIf="true">
             <p-fieldset [toggleable]="true" [collapsed]="collapseDivision">
               <p-header
                 ><label class="py-3 TheBoldFont-Bold text-bold3 fs-6"
                   >Division</label
                 ></p-header
               >

               <div class="p-col-12">
                 <div class="full-dropdown">
                   <p-dropdown
                     [options]="divisions"
                     placeholder="Select Division"
                     [(ngModel)]="selectedDivision"
                     optionLabel="name"
                     styleClass="m-0"
                     (onChange)="changeDivision()"
                   ></p-dropdown>
                 </div>
               </div>
             </p-fieldset>
           </div> -->

          <div *ngIf="!isValidated && teams.length == 0" class="alert">
            <label class="red-label">There must be a team to register.</label>
          </div>

          <div #teamInfo>
            <p-fieldset [toggleable]="true" [collapsed]="collapseTeam">
              <p-header
                ><label class="py-3 TheBoldFont-Bold text-bold3 fs-6"
                  >Team(s)</label
                ></p-header
              >

              <div class="p-col-12 p-0 mt-3 text-center">
                <label
                  >Please login as a coach to register multiple teams.</label
                >
              </div>

              <div class="disp-block p-col-12 text-right">
                <button
                  *ngIf="
                    (teamPlayerCoaches?.length == 0 && !isCoach) || isCoach
                  "
                  pButton
                  iconPos="left"
                  icon="pi pi-plus"
                  pRipple
                  type="button"
                  label="Add New Team"
                  class="p-button-raised p-button-danger"
                  (click)="selectDivision(true)"
                ></button>
                &nbsp;
                <button
                  pButton
                  *ngIf="isCoach"
                  iconPos="left"
                  icon="pi pi-plus"
                  pRipple
                  type="button"
                  label="Add Existing Team"
                  class="p-button-raised p-button-danger"
                  (click)="selectDivision(false)"
                ></button>
              </div>
              <div class="p-col-12 p-5">
                <div *ngIf="teams.length == 0; else hasTeams">
                  <div class="text-center">
                    <label class="TheBoldFont-Bold fs-3">
                      No Team Registered.
                    </label>
                  </div>
                </div>
                <ng-template #hasTeams>
                  <div *ngFor="let division of divisions">
                    <div *ngIf="division?.teamCount > 0" class="text-center">
                      <label
                        class="fs-normal font20"
                        style="
                          color: red;
                          text-decoration: underline;
                          font-size: 1em;
                        "
                        >REGISTERING FOR</label
                      >
                      <div>
                        <label
                          class="fs-normal font10"
                          style="color: red; font-size: 1em"
                          >{{ division?.name }}</label
                        >
                      </div>
                      <br />
                    </div>
                    <div
                      *ngFor="
                        let teamPlayerCoach of teamPlayerCoaches;
                        let i = index
                      "
                    >
                      <div
                        *ngIf="
                          division?.id === teamPlayerCoach?.team?.division.id
                        "
                        class="p-grid flex-center"
                      >
                        <div class="pos-relative">
                          <div class="d py-3">
                            <div
                              *ngIf="teamPlayerCoach?.team?.photo"
                              class="p-col-12 text-center"
                            >
                              <img
                                [src]="teamPlayerCoach?.team?.photo"
                                alt="Logo"
                                width="130px"
                                height="130px"
                              />
                            </div>
                            <div class="p-col-12 text-center">
                              <span
                                class="text-bold1 fs-normal font20 text-center"
                              >
                                {{ teamPlayerCoach?.team?.name }}</span
                              >
                            </div>
                            <div class="p-col-12 text-center">
                              <span class="text-bold1 fs-2 text-center">
                                {{ teamPlayerCoach?.team?.abbreviation }}</span
                              >
                            </div>
                            <div
                              class="p-col-12 text-center text-bold1 fs-normal font20"
                            >
                              {{ teamPlayerCoach?.division?.name }}
                            </div>
                          </div>

                          <button
                            pButton
                            type="button"
                            icon="pi pi-pencil"
                            (click)="editTeam(i)"
                            class="p-button-secondary mb-0 pos-absolute top-0"
                            style="height: 28px; width: 28px; right: 30px"
                            pTooltip="Edit Team?"
                          ></button>

                          <button
                            pButton
                            type="button"
                            icon="pi pi-times"
                            class="p-button-danger mb-0 pos-absolute top-0 right-0"
                            style="height: 28px; width: 28px"
                            (click)="deleteTeam(i)"
                            pTooltip="Delete Team?"
                          ></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </p-fieldset>
          </div>

          <div #coachInfo>
            <p-fieldset [toggleable]="true" [collapsed]="collapseCoach">
              <p-header
                ><label class="py-3 TheBoldFont-Bold text-bold3 fs-6"
                  >Coaches</label
                ></p-header
              >

              <!-- <div class="container-fields">
              <label class="py-3 TheOpenSans-Regular text-bold3"
                >First Name <span class="required-indicator">*</span>
              </label>
              <input
                formControlName="firstName"
                type="text"
                class="tournament-input-text"
                [ngClass]="isValidated ? null : 'validated'"
              />
              <div
                *ngIf="!isValidated && form.controls['firstName'].invalid"
                class="alert"
              >
                <label class="red-label">First name is required.</label>
              </div>
            </div>

            <div class="container-fields">
              <label class="py-3 TheOpenSans-Regular text-bold3"
                >Last Name <span class="required-indicator">*</span>
              </label>
              <input
                formControlName="lastName"
                type="text"
                class="tournament-input-text"
                [ngClass]="isValidated ? null : 'validated'"
              />
              <div
                *ngIf="!isValidated && form.controls['lastName'].invalid"
                class="alert"
              >
                <label class="red-label">Last name is required.</label>
              </div>
            </div>

            <div class="container-fields">
              <label class="py-3 TheOpenSans-Regular text-bold3"
                >Email <span class="required-indicator">*</span>
              </label>
              <input
                formControlName="email"
                type="text"
                class="tournament-input-text"
                [ngClass]="isValidated ? null : 'validated'"
                placeholder=""
              />
              <div
                *ngIf="!isValidated && form.controls['email'].invalid"
                class="alert"
              >
                <label class="red-label">Email is required.</label>
              </div>
            </div>

            <div class="container-fields">
              <label class="py-3 TheOpenSans-Regular text-bold3"
                >Mobile Phone <span class="required-indicator">*</span>
              </label>
              <input
                formControlName="phone"
                type="text"
                class="tournament-input-text"
                [ngClass]="isValidated ? null : 'validated'"
                maxlength="12"
                placeholder="+(X) XXX XXX XXXX"
              />
              <div
                *ngIf="!isValidated && form.controls['phone'].invalid"
                class="alert"
              >
                <label class="red-label">Mobile Phone is required.</label>
              </div>
            </div>

            <div
              *ngIf="registration?.coachAddress != NOT_REQ"
              class="container-fields"
            >
              <label class="py-3 TheOpenSans-Regular text-bold3"
                >Street Address
                <span
                  *ngIf="registration?.coachAddress == REQ"
                  class="required-indicator"
                  >*</span
                >
              </label>
              <input
                formControlName="coachAddress"
                type="text"
                class="tournament-input-text"
                [ngClass]="isValidated ? null : 'validated'"
              />
              <div
                *ngIf="!isValidated && form.controls['coachAddress'].invalid"
                class="alert"
              >
                <label class="red-label">Street Address is required.</label>
              </div>
            </div>

            <div
              *ngIf="registration?.coachCity != NOT_REQ"
              class="container-fields"
            >
              <label class="py-3 TheOpenSans-Regular text-bold3"
                >City
                <span
                  *ngIf="registration?.coachCity == REQ"
                  class="required-indicator"
                  >*</span
                >
              </label>
              <input
                formControlName="coachCity"
                type="text"
                class="tournament-input-text"
                [ngClass]="isValidated ? null : 'validated'"
              />

              <div
                *ngIf="!isValidated && form.controls['coachCity'].invalid"
                class="alert"
              >
                <label class="red-label">City is required.</label>
              </div>
            </div>

            <div
              *ngIf="registration?.coachState != NOT_REQ"
              class="container-fields"
            >
              <label class="py-3 TheOpenSans-Regular text-bold3"
                >State
                <span
                  *ngIf="registration?.coachState == REQ"
                  class="required-indicator"
                  >*</span
                >
              </label>

              <div class="gen-info-dropdown">
                <p-dropdown
                  [options]="states"
                  placeholder=" "
                  optionLabel="name"
                  optionValue="value"
                  formControlName="coachState"
                  autoWidth="false"
                  [style]="{ width: '100%' }"
                ></p-dropdown>
              </div>

              <div
                *ngIf="!isValidated && form.controls['coachState'].invalid"
                class="alert"
              >
                <label class="red-label">State is required.</label>
              </div>
            </div>

            <div
              class="container-fields"
              *ngIf="registration?.coachFacebook == 'NOT_REQUIRED' ? false : true"
            >
              <label class="py-3 TheOpenSans-Regular text-bold3"
                >Facebook Page
                <span
                  *ngIf="registration?.coachFacebook == REQ"
                  class="required-indicator"
                  >*</span
                >
              </label>
              <input
                formControlName="coachFacebook"
                type="text"
                class="tournament-input-text"
                [ngClass]="isValidated ? null : 'validated'"
              />
              <div
                *ngIf="!isValidated && form.controls['coachFacebook'].invalid"
                class="alert"
              >
                <label class="red-label">Facebook Page is required.</label>
              </div>
            </div>
            <div
              class="container-fields"
              *ngIf="registration?.coachTwitter == 'NOT_REQUIRED' ? false : true"
            >
              <label class="py-3 TheOpenSans-Regular text-bold3"
                >Twitter Handle
                <span
                  *ngIf="registration?.coachTwitter == REQ"
                  class="required-indicator"
                  >*</span
                >
              </label>
              <input
                formControlName="coachTwitter"
                type="text"
                class="tournament-input-text"
                [ngClass]="isValidated ? null : 'validated'"
              />
              <div
                *ngIf="!isValidated && form.controls['coachTwitter'].invalid"
                class="alert"
              >
                <label class="red-label">Twitter Handle is required.</label>
              </div>
            </div>
            <div
              class="container-fields"
              *ngIf="
                registration?.coachInstagram == 'NOT_REQUIRED' ? false : true
              "
            >
              <label class="py-3 TheOpenSans-Regular text-bold3"
                >Instagram Handle
                <span
                  *ngIf="registration?.coachInstagram == REQ"
                  class="required-indicator"
                  >*</span
                >
              </label>
              <input
                formControlName="coachInstagram"
                type="text"
                class="tournament-input-text"
                [ngClass]="isValidated ? null : 'validated'"
              />
              <div
                *ngIf="!isValidated && form.controls['coachInstagram'].invalid"
                class="alert"
              >
                <label class="red-label">Instagram Handle is required.</label>
              </div>
            </div>

            <div *ngIf="registration?.additionalCoachInfo != []">
              <div *ngFor="let coachInfo of registration?.additionalCoachInfo">
                <div
                  class="container-fields"
                  *ngIf="coachInfo?.status == 'NOT_REQUIRED' ? false : true"
                >
                  <label class="py-3 TheOpenSans-Regular text-bold3"
                    >{{ coachInfo?.name }}
                    <span
                      *ngIf="coachInfo?.status == REQ"
                      class="required-indicator"
                      >*</span
                    >
                  </label>
                  <input
                    *ngIf="coachInfo?.type === 'TEXTBOX'"
                    [formControlName]="generateFormGrp(coachInfo?.id)"
                    type="text"
                    class="tournament-input-text"
                    [ngClass]="isValidated ? null : 'validated'"
                  />
                  <textarea
                    *ngIf="coachInfo?.type === 'TEXTAREA'"
                    [formControlName]="generateFormGrp(coachInfo?.id)"
                    type="text"
                    rows="4"
                    class="tournament-inputarea-text mt-1"
                    [ngClass]="isValidated ? null : 'validated'"
                  ></textarea>

                </div>
              </div>
            </div> -->

              <div
                class="require-label-container p-col-12 p-0 mt-3 text-center"
              >
                This event requires
                <strong>{{ tourney?.minimumCoaches }}</strong> to
                <strong>{{ tourney?.maximumCoaches }}</strong> coach(es) per
                team.
              </div>

              <div
                *ngIf="!areCoachesValid"
                class="require-label-container p-col-12 p-0 mt-3 text-center alert"
              >
                Total coaches should be
                <strong>{{ tourney?.minimumCoaches }}</strong> to
                <strong>{{ tourney?.maximumCoaches }}.</strong>
              </div>

              <div
                class="disp-block p-col-12 text-right"
                style="margin-top: 40px"
              >
                <button
                  pButton
                  iconPos="left"
                  icon="pi pi-plus"
                  pRipple
                  type="button"
                  label="Add New Coach"
                  class="p-button-raised p-button-danger"
                  (click)="selectTeam(true)"
                ></button>
                &nbsp;
                <button
                  pButton
                  *ngIf="isCoach"
                  iconPos="left"
                  icon="pi pi-plus"
                  pRipple
                  type="button"
                  label="Add Existing Coach"
                  (click)="selectTeam(false)"
                  class="p-button-raised p-button-danger"
                ></button>
              </div>
              <div class="p-col-12 py-5 px-2">
                <div *ngIf="coaches.length == 0; else hasCoaches">
                  <div class="text-center">
                    <label class="TheBoldFont-Bold fs-3">
                      No Coach Registered.
                    </label>
                  </div>
                </div>
                <ng-template #hasCoaches>
                  <div
                    *ngFor="
                      let teamPlayerCoach of teamPlayerCoaches;
                      let i = index
                    "
                  >
                    <div class="text-center">
                      <label
                        class="fs-normal font20"
                        style="
                          color: red;
                          text-decoration: underline;
                          font-size: 1em;
                        "
                        >COACHING FOR</label
                      >
                      <div>
                        <label
                          class="fs-normal font10"
                          style="color: red; font-size: 1em"
                          >{{ teamPlayerCoach?.team?.name }}</label
                        >
                      </div>
                      <br />
                    </div>
                    <div class="card-container p-col-12">
                      <div *ngIf="headCoach != null" class="pos-relative mx-2">
                        <div class="d py-3">
                          <div class="p-col-12 text-center">
                            <span
                              class="text-bold1 fs-normal font20 text-center"
                            >
                              {{ headCoach?.firstName }}&nbsp;{{
                                headCoach?.lastName
                              }}
                            </span>
                          </div>
                          <div class="p-col-12 text-center">
                            <span class="text-bold1 fs-2 text-center">
                              PRIMARY COACH</span
                            >
                          </div>
                        </div>
                      </div>
                      <div
                        *ngFor="
                          let coach of teamPlayerCoach.coaches;
                          let j = index
                        "
                      >
                        <div>
                          <div class="pos-relative mx-2">
                            <div class="d py-3">
                              <!-- <div *ngIf="team?.photo" class="p-col-12 text-center">
                                <img
                                  [src]="team?.photo"
                                  alt="Logo"
                                  width="130px"
                                  height="130px"
                                />
                              </div> -->
                              <div class="p-col-12 text-center">
                                <span
                                  class="text-bold1 fs-normal font20 text-center"
                                >
                                  {{ coach?.firstName }}&nbsp;{{
                                    coach?.lastName
                                  }}
                                </span>
                              </div>
                              <div class="p-col-12 text-center">
                                <span class="text-bold1 fs-2 text-center">
                                  {{ coach?.role }}</span
                                >
                              </div>
                            </div>

                            <button
                              pButton
                              type="button"
                              icon="pi pi-pencil"
                              (click)="editCoach(i, j)"
                              class="p-button-secondary mb-0 pos-absolute top-0"
                              style="height: 28px; width: 28px; right: 30px"
                              pTooltip="Edit Coach?"
                            ></button>

                            <button
                              pButton
                              type="button"
                              icon="pi pi-times"
                              class="p-button-danger mb-0 pos-absolute top-0 right-0"
                              style="height: 28px; width: 28px"
                              (click)="deleteCoach(i, j)"
                              pTooltip="Delete Coach?"
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </p-fieldset>
          </div>

          <div
            *ngIf="registration?.requireAccount == 'OPTIONAL' && !isCoach"
            class="p-col-12 text-center"
          >
            <!-- <button
               class="btn-rounded-red b-shadow mx-2"
               (click)="toggleLogIn($event, opLogins)">
             </button>
             -->
            <button
              pButton
              label="Login as Coach"
              (click)="toggleOverlay($event)"
              class="p-button-raised p-button-rounded p-button-danger mx-2 px-5 py-3 mb-3"
            ></button>
            <p-overlayPanel #opLogins>
              <ng-template pTemplate>
                <app-login
                  [opLogin]="opLogin"
                  (coachLogged)="listenAuth($event)"
                  [isCoachReg]="true"
                ></app-login>
              </ng-template>
            </p-overlayPanel>
          </div>

          <div #playerInfo>
            <p-fieldset [toggleable]="true" [collapsed]="collapsePlayer">
              <p-header
                ><label class="py-3 TheBoldFont-Bold text-bold3 fs-6"
                  >Players</label
                ></p-header
              >
              <div class="require-label-container p-col-12 p-0 text-center">
                This event requires
                <strong>{{ tourney?.minPlayersPerRoster }}</strong> to
                <strong>{{ tourney?.totalPlayersPerRoster }}</strong> players
                per team.
              </div>

              <div
                *ngIf="!arePlayersValid"
                class="alert mt-3 p-col-12 p-0 text-center"
              >
                Total players should be
                <strong>{{ tourney?.minPlayersPerRoster }}</strong> to
                <strong>{{ tourney?.totalPlayersPerRoster }}.</strong>
              </div>

              <div class="disp-block p-col-12 text-right">
                <button
                  pButton
                  iconPos="left"
                  icon="pi pi-plus"
                  pRipple
                  type="button"
                  label="Add New Player"
                  class="p-button-raised p-button-danger"
                  (click)="selectTeam(true, false)"
                ></button>
                &nbsp;
                <button
                  pButton
                  *ngIf="isCoach"
                  iconPos="left"
                  icon="pi pi-plus"
                  pRipple
                  type="button"
                  label="Add Existing Player"
                  class="p-button-raised p-button-danger"
                  (click)="selectTeam(false, false)"
                ></button>
              </div>
              <div class="p-col-12 py-5 px-2">
                <div *ngIf="players.length == 0; else hasPlayers">
                  <div class="text-center">
                    <label class="TheBoldFont-Bold fs-3">
                      No Players Registered.
                    </label>
                  </div>
                </div>
                <ng-template #hasPlayers>
                  <div
                    *ngFor="
                      let teamPlayerCoach of teamPlayerCoaches;
                      let i = index
                    "
                  >
                    <div
                      *ngIf="teamPlayerCoach?.players.length > 0"
                      class="text-center"
                    >
                      <label
                        class="fs-normal font20"
                        style="
                          color: red;
                          text-decoration: underline;
                          font-size: 1em;
                        "
                        >PLAYING FOR</label
                      >
                      <div>
                        <label
                          class="fs-normal font10"
                          style="color: red; font-size: 1em"
                          >{{ teamPlayerCoach?.team?.name }}</label
                        >
                      </div>
                      <br />
                    </div>
                    <div class="card-container p-col-12">
                      <div
                        *ngFor="
                          let player of teamPlayerCoach?.players;
                          let j = index
                        "
                      >
                        <div>
                          <div class="pos-relative mx-2">
                            <div class="card-box py-3">
                              <!-- <div *ngIf="team?.photo" class="p-col-12 text-center">
                                  <img
                                    [src]="team?.photo"
                                    alt="Logo"
                                    width="130px"
                                    height="130px"
                                  />
                                </div> -->
                              <div class="p-col-12 text-center">
                                <span
                                  class="text-bold1 fs-normal font20 text-center"
                                >
                                  {{ player?.fullName }}</span
                                >
                              </div>
                              <div class="p-col-12 text-center">
                                <span class="text-bold1 fs-2 text-center">
                                  Jersey #: {{ player?.jerseyNumber1 }}</span
                                >
                              </div>
                              <div class="p-col-12 text-center">
                                <span class="text-bold1 fs-2 text-center">
                                  Position: {{ player?.position }}</span
                                >
                              </div>
                              <!-- <div class="p-col-12 text-center text-bold1 fs-normal font20">{{teams[0].name}}</div> -->
                            </div>

                            <button
                              pButton
                              type="button"
                              icon="pi pi-pencil"
                              (click)="editPlayer(i, j)"
                              class="p-button-secondary mb-0 pos-absolute top-0"
                              style="height: 28px; width: 28px; right: 30px"
                              pTooltip="Edit Player?"
                            ></button>

                            <button
                              pButton
                              type="button"
                              icon="pi pi-times"
                              class="p-button-danger mb-0 pos-absolute top-0 right-0"
                              style="height: 28px; width: 28px"
                              (click)="deletePlayer(i, j)"
                              pTooltip="Delete Player?"
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </p-fieldset>
          </div>

          <div #payment>
            <p-fieldset
              *ngIf="registration?.requirePayment != 'NOT_REQUIRED'"
              [toggleable]="true"
              [collapsed]="collapseDivision"
            >
              <p-header
                ><label class="py-3 TheBoldFont-Bold text-bold3 fs-6"
                  >Payment</label
                ></p-header
              >

              <div class="p-col-12 py-5">
                <div *ngIf="teams.length == 0; else havePayingTeams">
                  <div class="text-center">
                    <label class="TheBoldFont-Bold fs-3">
                      No Teams Registered.
                    </label>
                  </div>
                </div>

                <ng-template #havePayingTeams>
                  <div
                    class="p-col-12 px-2"
                    style="display: flex; flex-direction: row; width: 100%"
                  >
                    <div class="p-col-1"></div>
                    <div class="p-col-3 p-md-3 text-center">
                      <label
                        class="fs-normal font10"
                        style="font-weight: bold; text-decoration: underline"
                        >Team(s)</label
                      >
                    </div>
                    <div class="p-col-3 p-md-4 text-center">
                      <label
                        class="fs-normal font10"
                        style="font-weight: bold; text-decoration: underline"
                        >Division</label
                      >
                    </div>
                    <div class="p-col-5 p-md-3 text-center">
                      <label
                        class="fs-normal font10"
                        style="font-weight: bold; text-decoration: underline"
                        >Payment</label
                      >
                    </div>
                    <div class="p-col-0 p-md-1"></div>
                  </div>
                  <div
                    class="p-col-12"
                    style="
                      display: flex;
                      flex-direction: row;
                      width: 100%;
                      align-items: center;
                    "
                    *ngFor="let tpc of teamPlayerCoaches; let i = index"
                  >
                    <div class="p-col-1">
                      <label class="fs-normal font10" style="font-weight: bold"
                        >{{ i + 1 }}:</label
                      >
                    </div>
                    <div class="p-col-3 p-md-3 text-center">
                      <label
                        class="fs-normal font10"
                        style="font-weight: bold"
                        >{{ tpc?.team?.name }}</label
                      >
                    </div>
                    <div class="p-col-3 p-md-4 text-center">
                      <label
                        class="fs-normal font10"
                        style="font-weight: bold"
                        >{{ tpc?.team?.division.name }}</label
                      >
                    </div>
                    <div
                      class="p-col-5 p-md-3 text-center"
                      formGroupName="payments"
                    >
                      <div class="full-dropdown">
                        <p-dropdown
                          [options]="payments"
                          [formControlName]="generatePaymentFormCtrl(i)"
                          placeholder="Select Payment"
                          optionLabel="name"
                          styleClass="m-0"
                          (onChange)="calculateTotalPayment()"
                        ></p-dropdown>
                      </div>
                    </div>
                    <div class="p-col-0 p-md-1"></div>
                  </div>
                  <div class="mt-3">
                    <label class="fs-normal font10" style="font-weight: bold"
                      >Total: ${{ totalPayment }}</label
                    >
                  </div>
                </ng-template>
              </div>

              <div class="payment-container">
                <div class="container-fields">
                  <label class="py-3 TheOpenSans-Regular text-bold3"
                    >Email
                    <span
                      *ngIf="registration?.requirePayment != 'OPTIONAL'"
                      class="required-indicator"
                      >*</span
                    >
                  </label>
                  <input
                    formControlName="ccname"
                    type="text"
                    class="tournament-input-text"
                  />
                  <div
                    *ngIf="!isValidated && form.controls['ccname'].invalid"
                    class="alert"
                  >
                    <label class="red-label"
                      >Credit Card Name is required.</label
                    >
                  </div>
                </div>
                <ngx-stripe-card
                  [options]="cardOptions"
                  [elementsOptions]="elementsOptions"
                ></ngx-stripe-card>
                <!-- <div class="container-fields">
                  <label class="py-3 TheOpenSans-Regular text-bold3"
                    >Credit Card Number
                    <span
                      *ngIf="registration?.requirePayment != 'OPTIONAL'"
                      class="required-indicator"
                      >*</span
                    >
                  </label>
                  <input
                    formControlName="ccnumber"
                    type="text"
                    class="tournament-input-text"
                    maxlength="16"
                  />
                  <div
                    *ngIf="!isValidated && form.controls['ccnumber'].invalid"
                    class="alert"
                  >
                    <label class="red-label"
                      >Credit Card Number is required.</label
                    >
                  </div>
                </div>

                <div class="container-fields">
                  <label class="py-3 TheOpenSans-Regular text-bold3"
                    >Expiration Date
                    <span
                      *ngIf="registration?.requirePayment != 'OPTIONAL'"
                      class="required-indicator"
                      >*</span
                    >
                  </label>
                  <input
                    formControlName="ccexpiration"
                    type="text"
                    class="tournament-input-text"
                    placeholder="MM/YYYY"
                    maxlength="7"
                  />
                  <div
                    *ngIf="
                      !isValidated && form.controls['ccexpiration'].invalid
                    "
                    class="alert"
                  >
                    <label class="red-label"
                      >Expiration Date is required.</label
                    >
                  </div>
                </div>

                <div class="container-fields">
                  <label class="py-3 TheOpenSans-Regular text-bold3"
                    >Security Code
                    <span
                      *ngIf="registration?.requirePayment != 'OPTIONAL'"
                      class="required-indicator"
                      >*</span
                    >
                  </label>
                  <input
                    formControlName="cccode"
                    type="text"
                    class="tournament-input-text"
                    maxlength="3"
                    placeholder="CVC/CVV"
                  />
                  <div
                    *ngIf="!isValidated && form.controls['cccode'].invalid"
                    class="alert"
                  >
                    <label class="red-label">Security Code is required.</label>
                  </div>
                  <div *ngIf="stripeError != ''" class="alert">
                    <label class="red-label">{{ stripeError }}</label>
                  </div>
                </div> -->
              </div>
            </p-fieldset>
          </div>

          <p-fieldset [toggleable]="true" [collapsed]="collapseDivision">
            <p-header
              ><label class="py-3 TheBoldFont-Bold text-bold3 fs-6"
                >Terms</label
              ></p-header
            >

            <div
              *ngIf="
                registration?.showTerms == 'TERMS_ONLY' ||
                registration?.showTerms == 'BOTH'
              "
              class="container-fields"
            >
              <label class="py-3 TheOpenSans-Regular text-bold3"
                >Terms and Conditions

                <textarea
                  [disabled]="true"
                  rows="4"
                  cols="30"
                  [value]="terms"
                  class="tournament-inputarea-text mt-3"
                ></textarea>
              </label>
            </div>

            <div
              *ngIf="
                registration?.showTerms == 'REFUND_ONLY' ||
                registration?.showTerms == 'BOTH'
              "
              class="container-fields"
            >
              <label class="py-3 TheOpenSans-Regular text-bold3"
                >Refund Policy

                <textarea
                  [placeholder]="''"
                  [disabled]="true"
                  rows="14"
                  cols="30"
                  [value]="policy"
                  class="tournament-inputarea-text mt-3"
                ></textarea>
              </label>
            </div>

            <div class="container-fields">
              <!--<label class="py-3 TheOpenSans-Regular text-bold3">Accept Terms <span class="required-indicator">*</span>-->
              <div class="p-col-12 p-grid grid">
                <div
                  *ngIf="
                    registration?.showTerms == 'TERMS_ONLY' ||
                    registration?.showTerms == 'BOTH'
                  "
                  class="p-col-6 mt-3"
                >
                  <p-checkbox
                    formControlName="isAccept"
                    [binary]="true"
                    class="use-white-label"
                    label="I agree to the Terms and Conditions."
                  >
                  </p-checkbox>
                </div>
                <div
                  *ngIf="
                    registration?.showTerms == 'REFUND_ONLY' ||
                    registration?.showTerms == 'BOTH'
                  "
                  class="p-col-6 mt-3"
                >
                  <p-checkbox
                    formControlName="isRefundAccept"
                    [binary]="true"
                    class="use-white-label"
                    label="I agree to the Refund Policy."
                  >
                  </p-checkbox>
                </div>
              </div>
              <!--</label>-->
              <div
                *ngIf="!isValidated && form.controls['isAccept'].invalid"
                class="alert"
              >
                <label class="red-label">Accepting Terms is required.</label>
              </div>
              <div
                *ngIf="!isValidated && form.controls['isRefundAccept'].invalid"
                class="alert"
              >
                <label class="red-label"
                  >Accepting Refund Policy is required.</label
                >
              </div>
            </div>
          </p-fieldset>

          <div class="p-col-12 text-center">
            <button
              pButton
              label="Register"
              type="submit"
              (click)="createToken()"
              class="p-button-raised p-button-rounded p-button-danger mx-2 px-5 py-3 mb-3"
            ></button>
          </div>
          <br />
          <br />
        </div>
      </div>
    </div>
  </form>
</div>
