<p-accordion>
  <p-accordionTab>
    <ng-template pTemplate="header">
      <div class="flex flex-row w-100" style="justify-content: space-between">
        <div>
          {{ bracketName }}
          <i
            class="pi pi-pencil ml-2"
            (click)="handleOpenChangeName($event)"
          ></i>
        </div>
        <i
          class="pi pi-trash"
          style="color: red"
          (click)="handleClearAll($event)"
        ></i>
      </div>
    </ng-template>
    <div class="container-bracketset">
      <button
        pButton
        id="create-btn"
        type="button"
        label="Save"
        *ngIf="isHasSet"
        class="p-button-raised p-button-rounded create-btn p-button-danger mx-2 px-5 py-3"
        (click)="handleSave()"
      ></button>

      <button
        pButton
        id="create-btn"
        type="button"
        label="Save As Template"
        *ngIf="isHasSet"
        class="p-button-raised p-button-rounded create-btn p-button-danger mx-2 px-5 py-3"
      ></button>

      <button
        pButton
        id="create-btn"
        type="button"
        *ngIf="isHasSet"
        label="Change Size"
        class="p-button-raised p-button-rounded create-btn p-button-danger mx-2 px-5 py-3"
      ></button>

      <button
        pButton
        id="create-btn"
        type="button"
        *ngIf="isHasSet"
        label="Clear All"
        class="p-button-raised p-button-rounded create-btn p-button-danger mx-2 ml-5 py-3"
      ></button>

      <button
        pButton
        id="create-btn"
        type="button"
        *ngIf="isHasSet"
        label="Delete Braket"
        class="p-button-raised p-button-rounded create-btn p-button-danger mx-2 px-5 py-3"
      ></button>
    </div>

    <div
      class="container-main"
      [ngClass]="{ show: isHasSet, hide: !isHasSet }"
      #scrollContainer
      (mousedown)="onMouseDown($event)"
      (wheel)="onWheel($event)"
      (mouseup)="onMouseUp()"
      (mouseleave)="onMouseUp()"
      (mousemove)="onMouseMove($event)"
    >
      <div
        class="container-bracket"
        #stage
        [ngStyle]="{
          width: stageWidth + '%',
          'transform-origin': ' 50% 50%',
          transform: 'scale(' + zoomScale + ')'
        }"
        [style.height.px]="stageHeight"
      >
        <div class="bracket-cont" #bracket></div>
      </div>
    </div>
    <div class="container-bracketset">
      <button
        pButton
        id="create-btn"
        type="button"
        label="Add"
        *ngIf="isHasSet"
        class="p-button-raised p-button-rounded create-btn p-button-danger mx-2 px-5 py-3"
        (click)="openEditBracketDialog()"
      >
        <!-- <p-dialog
          header="Select Bracke Shape"
          [(visible)]="this.isOpenEditBracket"
        >
          <div class="container-bracketsets">
            <p-selectButton
              class="p-grid"
              [options]="bracketTypes"
              [(ngModel)]="selectedBracketType"
            >
              <div
                class="flex gap-4 justify-content-center align-items-center flex-column mt-4"
                *ngFor="let item of bracketTypes"
              >
                <div style="display: block">
                  <div
                    *ngIf="item.value == 'MIDDLE'"
                    class="middle-top-bracket-edit"
                  ></div>
                  <div
                    [ngClass]="{
                      'right-facing-edit': item.value == 'RIGHT_FACING',
                      'left-facing-edit': item.value == 'LEFT_FACING',
                      'middle-bracket-edit': item.value == 'MIDDLE',
                      'bracket-winner-edit': item.value == 'BRACKET_WINNER',
                      'box-bracket-edit': item.value == 'BOX'
                    }"
                  ></div>
                  <div
                    *ngIf="item.value == 'MIDDLE'"
                    class="middle-bottom-bracket-edit"
                  ></div>
                </div>

                <h5>{{ item.name }}</h5>
              </div>
            </p-selectButton>
            <div class="flex container-btn">
              <button
                class="btn-rounded-red btn-reset"
                (click)="onSubmitEditBracket()"
              >
                Submit
              </button>
            </div>
          </div>
        </p-dialog> -->
      </button>

      <button
        pButton
        id="create-btn"
        type="button"
        label="Arrow Buttons"
        *ngIf="isHasSet"
        class="p-button-raised p-button-rounded create-btn p-button-danger mx-2 px-5 py-3"
      ></button>

      <button
        pButton
        id="create-btn"
        type="button"
        label="Zoom Buttons"
        *ngIf="isHasSet"
        class="p-button-raised p-button-rounded create-btn p-button-danger mx-2 px-5 py-3"
      ></button>

      <button
        pButton
        id="create-btn"
        type="button"
        *ngIf="isHasSet"
        label="Undo "
        class="p-button-raised p-button-rounded create-btn p-button-danger mx-2 px-5 py-3"
        (click)="undoChanges()"
      ></button>
    </div>

    <p-dialog header="Select Bracket Shape" [(visible)]="this.isOpenBracket">
      <div class="container-bracketsets">
        <p-selectButton
          class="p-grid"
          [options]="bracketTypes"
          [(ngModel)]="selectedBracketType"
        >
          <div
            class="flex gap-4 justify-content-center align-items-center flex-column mt-4"
            *ngFor="let item of bracketTypes"
          >
            <div
              *ngIf="item.value != 'MIDDLE'"
              [ngClass]="{
                'right-facing': item.value == 'RIGHT_FACING',
                'left-facing': item.value == 'LEFT_FACING',
                'bracket-winner': item.value == 'BRACKET_WINNER',
                'box-bracket': item.value == 'BOX'
              }"
            ></div>

            <div *ngIf="item.value == 'MIDDLE'" class="middle-bracket">
              <div class="top-line"></div>
              <div class="bottom-line"></div>
            </div>
            <div>
              <h5>{{ item.name }}</h5>
            </div>
          </div>
        </p-selectButton>
        <div class="flex container-btn">
          <button class="btn-rounded-red btn-reset">Submit</button>
        </div>
      </div>
    </p-dialog>

    <p-dialog header="Change Name" [(visible)]="isChangeName">
      <div class="flex flex-column">
        <input
          type="text"
          pInputText
          [(ngModel)]="bracketName"
          maxlength="25"
        />
        <div class="flex container-btn">
          <button
            class="btn-rounded-red btn-reset"
            (click)="handleSaveBracketName()"
          >
            Submit
          </button>
        </div>
      </div>
    </p-dialog>
  </p-accordionTab>
</p-accordion>
