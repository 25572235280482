import { CdkDragEnd, CdkDragMove } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { Bracket } from '@core/models/brackets';
import { BracketSources, SubSources } from '@core/models/bracket-sources';
import { DeleteBracketComponent } from '@components/modal/delete-bracket/delete-bracket.component';
import { DeleteTopTeamComponent } from '@components/modal/delete-top-team/delete-top-team.component';
import { DeleteBottomTeamComponent } from '@components/modal/delete-bottom-team/delete-bottom-team.component';
import { BracketService } from '@core/services/bracket-service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BracketBuilderComponent } from '../bracket-builder.component';
import { ToastService } from '@core/services';
import { Subject } from 'rxjs';
import { BracketSet } from '@core/models/bracket-set';
import { EditBracketComponent } from '@components/modal/edit-bracket/edit-bracket.component';
interface SourceOptions {
  name: string;
  code: string;
}
@Component({
  selector: 'app-bracket',
  templateUrl: './bracket.component.html',
  styleUrls: ['./bracket.component.scss'],
})
export class BracketComponent implements OnInit, AfterViewInit {
  @ViewChild('resizeBox') resizeBox: ElementRef;
  @ViewChild('dragHandleCorner') dragHandleCorner: ElementRef;
  @ViewChild('dragHandleRight') dragHandleRight: ElementRef;
  @ViewChild('dragHandleLeft') dragHandleLeft: ElementRef;
  @ViewChild('dragHandleBottom') dragHandleBottom: ElementRef;
  @ViewChild('dragDeleteBtn') dragDeleteBtn: ElementRef;
  @ViewChild('dragGearBtn') dragGearBtn: ElementRef;

  // @Input('left') public left: number;
  // @Input('top') public top: number;
  // @Input('width') public width: number;
  // @Input('height') public height: number;
  // @Input('id') public id: string;
  // @Input('mainOptions') public mainOptions: BracketSources[];
  // @Input('bracketObject') public bracketObject: Bracket;
  @Input('parentRef') public parentRef: BracketBuilderComponent;
  @Input('isPreview') public isPreview: boolean;
  // @Input('bracketType') public bracketType: string;
  @Input('isFromUndo') public isFromUndo: Boolean;
  // @Input('dx') public dx: string;
  // @Input('dy') public dy: string;

  @Input('bracket') public bracket: Bracket;
  @Input('bracketSet') public bracketSet: BracketSet;
  @Input('mainOptions') public mainOptions: BracketSources[];

  @Output('onDeleteBracket') onDeleteEventBracket: EventEmitter<any> =
    new EventEmitter();
  isDragDisabled = false;

  public mouse: { x: number; y: number };

  options: SourceOptions[];
  saveChanged$: Subject<any> = new Subject<any>();
  saveBracketCoordinate$: Subject<any> = new Subject<any>();
  saveBracketDimension$: Subject<any> = new Subject<any>();

  topSubOptions: SubSources[];
  bottomSubOptions: SubSources[];
  topSubSelected: SubSources;
  bottomSubSelected: SubSources;
  topSubSourceId: number;
  bottomSubSourceId: number;

  topSelectedOption: BracketSources;
  bottomSelectedOption: BracketSources;

  tempUndoData: any;
  data: any;
  version = -1;
  itemCount = 2;
  bracketName: string;
  origBracketName: string;
  topTeam: string;
  bottomTeam: string;
  bracketText = '';

  r1: BracketSources;
  r2: BracketSources;

  isOpenEditBracket = false;
  isAutoZIndex = false;
  isMouseEnded = false;
  isFromResize = false;
  selectedBracketType: string;

  defaultFirstSelected: any;
  defaultSecondSelected: any;

  bracketHeight: number;
  bracketWidth: number;
  bracketTypes = [
    { name: 'Right Facing', value: 'RIGHT_FACING' },
    { name: 'Left Facing', value: 'LEFT_FACING' },
    { name: 'Middle', value: 'MIDDLE' },
    { name: 'Bracket Winner', value: 'BRACKET_WINNER' },
    { name: 'Box', value: 'BOX' },
    { name: 'Text', value: 'TEXT' },
    { name: 'Header', value: 'HEADER', default: true },
  ];

  dynamicId = 0;
  baseZIndex = 999;
  dragPosition = { x: 0, y: 0 };
  deleteRef: DynamicDialogRef;
  deleteTopRef: DynamicDialogRef;
  deleteBottomRef: DynamicDialogRef;

  @HostListener('mousemove', ['$event'])
  public mousemove(event: any): void {
    setTimeout(() => {
      this.isMouseEnded = false;
    }, 500);
  }

  @HostListener('mousedown', ['$event'])
  public mousedown(event: any): void {
    this.bracketService.movingBracket$.next(true);
  }
  @HostListener('mouseup', ['$event'])
  public mouseup(event: any): void {
    if (this.isFromResize) {
      this.isFromResize = false;
      this.bracketService.undoData$.next(this.tempUndoData);
      this.bracketService.setBracketDimension$.next(this.tempUndoData);
    }
    this.isMouseEnded = true;
    this.isDragDisabled = false;
    setTimeout(() => {
      this.bracketService.movingBracket$.next(false);
    }, 100);
  }

  @HostListener('window:click', ['$event'])
  onMouseClick(event: MouseEvent) {}

  get resizeBoxElement(): HTMLElement {
    return this.resizeBox.nativeElement;
  }

  get dragHandleCornerElement(): HTMLElement {
    return this.dragHandleCorner.nativeElement;
  }

  get dragDeleteElement(): HTMLElement {
    return this.dragDeleteBtn.nativeElement;
  }

  get dragGearElement(): HTMLElement {
    return this.dragGearBtn.nativeElement;
  }

  get dragHandleRightElement(): HTMLElement {
    return this.dragHandleRight.nativeElement;
  }

  get dragHandleLeftElement(): HTMLElement {
    return this.dragHandleLeft.nativeElement;
  }

  get dragHandleBottomElement(): HTMLElement {
    return this.dragHandleBottom.nativeElement;
  }
  constructor(
    private ngZone: NgZone,
    private bracketService: BracketService,
    private dialogService: DialogService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.origBracketName = this.bracket.name;
    this.bracketHeight = this.calculateHeight(this.bracket.y1, this.bracket.y2);
    this.bracketWidth = this.calculateWidth(this.bracket.x3, this.bracket.x1);
    this.bracketName = this.bracket.name;
  }

  ngAfterViewInit(): void {
    // if (!this.isFromUndo) {
    //   let data = {
    //     bracketId: this.bracket.id,
    //     height: this.bracketHeight,
    //     width: this.bracketWidth,
    //     x: this.bracket.x1,
    //     y: this.bracket.y1,
    //     topSource: this.bracket.topSource,
    //     bottomSource: this.bracket.bottomSource,
    //     topSubSourceId: this.topSubSourceId,
    //     bottomSubSourceId: this.bottomSubSourceId,
    //     bracketType: this.bracket.bracketType,
    //     bracketName: this.bracket.name,
    //     br: this.bracket,
    //     id: this.dynamicId++,
    //   };
    //   this.bracketName = this.bracket.name;
    //   this.bracketService.undoData$.next(data);
    // }
    // this.isFromUndo = false;

    this.setAllHandleTransform();
    this.dragPosition.x = this.bracket.x1;
    this.dragPosition.y = this.bracket.y1;

    this.saveBracketDimension$
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((dimensions) => {
        this.bracketService.saveBracketDimensions(dimensions).then((res) => {
          this.version = res?.version;
        });
      });

    this.saveBracketCoordinate$
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((coordinates) => {
        this.bracketService.saveBracketCoordinates(coordinates).then((res) => {
          this.version = res?.version;
        });
      });

    this.bracketService.bracketOptions$.subscribe((options) => {
      this.mainOptions = options;
    });

    this.bracketService.setBrackets$.subscribe((bracket) => {
      this.updateGameSubOptions(bracket);
    });

    this.initializeSources();
  }

  initializeSources() {
    this.initChangeTopOption();
    this.initChangeBottomOption();

    this.topSubSelected = this.topSubOptions?.find((option) => {
      return option.refId === this.bracket.topSubSourceId;
    });

    this.bottomSubSelected = this.bottomSubOptions?.find((option) => {
      return option.refId === this.bracket.bottomSubSourceId;
    });
  }

  setAllHandleTransform() {
    const rect = this.resizeBoxElement.getBoundingClientRect();
    this.bracketHeight = rect.height;
    this.bracketWidth = rect.width;
    this.setHandleTransform(this.dragHandleCornerElement, rect, 'both');
    this.setHandleTransform(this.dragHandleRightElement, rect, 'x');
    this.setHandleTransform(this.dragHandleBottomElement, rect, 'y');
    this.setHandleTransform(this.dragDeleteElement, rect, 'delete');
    this.setHandleTransform(this.dragGearElement, rect, 'gear-right');
  }

  reloadOptions() {
    const topSelectedOption = this.mainOptions.find(
      (mainOption: BracketSources) => {
        return mainOption.id === this.bracket.topSource?.id;
      }
    );
    this.topSelectedOption = topSelectedOption;

    const bottomSelectedOption = this.mainOptions.find(
      (mainOption: BracketSources) => {
        return mainOption.id === this.bracket.bottomSource?.id;
      }
    );
    this.bottomSelectedOption = bottomSelectedOption;

    this.topSubOptions = this.topSelectedOption
      ? this.topSelectedOption.subSources
      : [];
    this.bottomSubOptions = this.bottomSelectedOption
      ? this.bottomSelectedOption.subSources
      : [];
  }

  initChangeTopOption() {
    const topSelectedOption = this.mainOptions.find(
      (mainOption: BracketSources) => {
        return mainOption.id === this.bracket.topSource?.id;
      }
    );
    this.topSelectedOption = topSelectedOption;

    let options: SubSources[] = this.mainOptions.find(
      (mainOption: BracketSources) => {
        return mainOption.id === this.bracket.topSource?.id;
      }
    )?.subSources;

    if (
      this.topSelectedOption?.refType === 'WINNER' ||
      this.topSelectedOption?.refType === 'LOSER'
    ) {
      this.topSubOptions = options.filter((option) => {
        return option.description != this.origBracketName;
      });
    } else {
      this.topSubOptions = options;
    }
  }

  initChangeBottomOption() {
    const bottomSelectedOption = this.mainOptions.find(
      (mainOption: BracketSources) => {
        return mainOption.id === this.bracket.bottomSource?.id;
      }
    );
    this.bottomSelectedOption = bottomSelectedOption;

    let options: SubSources[] = this.mainOptions.find(
      (mainOption: BracketSources) => {
        return mainOption.id === this.bracket.bottomSource?.id;
      }
    )?.subSources;

    if (
      this.bottomSelectedOption?.refType === 'WINNER' ||
      this.bottomSelectedOption?.refType === 'LOSER'
    ) {
      this.bottomSubOptions = options.filter((option) => {
        return option.description != this.origBracketName;
      });
    } else {
      this.bottomSubOptions = options;
    }
  }

  onChangeTopOption(e: any, isFirst = false) {
    let options: SubSources[] = this.mainOptions.find(
      (mainOption: BracketSources) => {
        return mainOption.id === this.topSelectedOption.id;
      }
    ).subSources;

    if (
      this.topSelectedOption?.refType === 'WINNER' ||
      this.topSelectedOption?.refType === 'LOSER'
    ) {
      this.topSubOptions = options.filter((option) => {
        return option.description != this.origBracketName;
      });
    } else {
      this.topSubOptions = options;
    }
    this.topSubSelected = null;

    this.triggerSetBracketData(isFirst);
    // this.triggerUndoData();
  }

  onChangeTopSubOptions() {
    this.triggerSetBracketData(false);
    // this.triggerUndoData();
  }

  onChangeBottomOption(e: any, isFirst = false) {
    let options: SubSources[] = this.mainOptions.find(
      (mainOption: BracketSources) => {
        return mainOption.id === this.bottomSelectedOption.id;
      }
    ).subSources;

    if (
      this.bottomSelectedOption?.refType === 'WINNER' ||
      this.bottomSelectedOption?.refType === 'LOSER'
    ) {
      this.bottomSubOptions = options.filter((option) => {
        return option.description != this.origBracketName;
      });
    } else {
      this.bottomSubOptions = options;
    }
    this.bottomSubSelected = null;

    this.triggerSetBracketData(isFirst);
    this.triggerUndoData();
  }

  onChangeBottomSubOptions() {
    this.triggerSetBracketData(false);
    this.triggerUndoData();
  }

  validateBracketSources() {
    const setToError = (bracket: any, className: string) => {
      const dropdowns = bracket.getElementsByClassName(className);

      for (let k = 0; k < dropdowns.length; k++) {
        let dropdown: any = dropdowns[k];
        dropdown.style.borderWidth = '1px';
        dropdown.style.borderStyle = 'solid';
        dropdown.style.borderColor = 'red';
      }

      this.bracketService.onValidate$.next(false);
    };

    const setToValid = (bracket: any, className: string) => {
      const dropdowns = bracket.getElementsByClassName(className);

      for (let k = 0; k < dropdowns.length; k++) {
        let dropdown: any = dropdowns[k];
        dropdown.style.borderWidth = 0;
      }

      this.bracketService.onValidate$.next(true);
    };

    this.bracketService
      .validateBracketSources(this.bracket.division.id)
      .then((errors) => {
        const brackets = document.getElementsByClassName('bracket-item');

        for (let j = 0; j < brackets.length; j++) {
          let found = false;
          const bracket = brackets.item(j);
          for (let i = 0; i < errors.length; i++) {
            if (bracket.id == errors[i].id) {
              found = true;
              const className =
                errors[i].error == 'TOP'
                  ? 'advanced-top-team-border'
                  : 'advanced-bottom-team-border';
              setToError(bracket, className);
            }
          }
          if (!found) {
            setToValid(bracket, 'advanced-top-team-border');
            setToValid(bracket, 'advanced-bottom-team-border');
          }
        }
      });
  }
  onEditBracket(bracketType: string) {
    this.deleteRef = this.dialogService.open(EditBracketComponent, {
      header: 'Change Bracket',
      width: '800px',
      closable: false,
      contentStyle: { overflow: 'visible' },
      data: {
        bracket: this.bracket,
        bracketSet: this.bracketSet,
        bracketType: bracketType,
      },
    });

    this.deleteRef.onClose.subscribe((res: any) => {});
  }

  onDeleteBracket() {
    this.deleteRef = this.dialogService.open(DeleteBracketComponent, {
      header: 'Delete Bracket',
      width: '500px',
      closable: false,
      contentStyle: { overflow: 'visible' },
      data: this.bracket.id,
    });

    this.deleteRef.onClose.subscribe((res: any) => {});
  }

  onDeleteTopTeam() {
    this.deleteTopRef = this.dialogService.open(DeleteTopTeamComponent, {
      header: 'Delete Top Team',
      width: '500px',
      closable: false,
      data: this.bracket.id,
    });

    this.deleteTopRef.onClose.subscribe((res: any) => {
      if (res) {
        this.topTeam = null;
      }
    });
  }

  onDeleteBottomTeam() {
    this.deleteBottomRef = this.dialogService.open(DeleteBottomTeamComponent, {
      header: 'Delete Bottom Team',
      width: '500px',
      closable: false,
      data: this.bracket.id,
    });

    this.deleteBottomRef.onClose.subscribe((res: any) => {
      if (res) {
        this.bottomTeam = null;
      }
    });
  }

  onChangeName(e: Event) {
    let data = {
      id: this.bracket.id,
      bracketSet: this.bracketSet,
      bracketType: this.bracket.bracketType,
      name: this.bracket.name,
      topSource: this.r1,
      bottomSource: this.r2,
      topSubSourceId: this.topSubSelected?.refId,
      bottomSubSourceId: this.bottomSubSelected?.refId,
      x1: this.bracket.x1,
      y1: this.bracket.y1,
      x2: this.bracket.x2,
      y2: this.bracket.y2,
      version: this.version == -1 ? this.bracket.version : this.version,
    };

    this.bracketService.setBrackets$.next(data);
    // this.bracketService.setBracketName$.next({data})
    // this.bracketService.saveBracket(data).then((res) => {
    //   this.version = res?.version;
    // });
  }

  setHandleTransform(
    dragHandle: HTMLElement,
    targetRect: ClientRect | DOMRect,
    position:
      | 'x'
      | 'y'
      | 'both'
      | 'delete'
      | 'delete-reverse'
      | 'gear-right'
      | 'gear-left'
  ) {
    const dragRect = dragHandle.getBoundingClientRect();
    const translateX = targetRect.width - dragRect.width;
    const translateY = targetRect.height - dragRect.height;
    const gearRightX = translateX + 5;
    const deleteRightX = translateX - 10;
    if (position === 'x') {
      dragHandle.style.transform = `translate(${translateX}px, 0)`;
    }

    if (position === 'y') {
      dragHandle.style.transform = `translate(0, ${translateY}px)`;
    }

    if (position === 'both') {
      dragHandle.style.transform = `translate(${translateX}px, ${translateY}px)`;
    }
    if (position == 'delete') {
      dragHandle.style.transform = `translate(${deleteRightX}px, 0)`;
    }
    if (position == 'gear-right') {
      dragHandle.style.transform = `translate(${gearRightX}px, 0)`;
    }
    if (position == 'gear-left') {
      dragHandle.style.transform = `translate(20px, 0)`;
    }
    if (position == 'delete-reverse') {
      dragHandle.style.transform = `translate(5px, 0)`;
    }
  }

  dragEnded(event: CdkDragEnd) {
    this.isDragDisabled = false;
    const elRect = this.resizeBoxElement.getBoundingClientRect();
    const width = elRect.width;
    const height = elRect.height;
    const elSource = event.source.getFreeDragPosition();
    const elY2 = height + elSource.y;

    let data = {
      bracketId: this.bracket.id,
      height: height,
      width: width,
      x: elSource.x,
      y: elSource.y,
      topSource: this.bracket.topSource,
      bottomSource: this.bracket.bottomSource,
      topSubSourceId: this.topSubSelected?.refId,
      bottomSubSourceId: this.bottomSubSelected?.refId,
      bracketType: this.bracket.bracketType,
      bracketName: this.bracket.name,
    };

    this.bracketService.undoData$.next(data);
    this.bracketService.setBracketCoordinates$.next(data);

    // this.saveBracketCoordinate$.next(data);
  }

  dragData(event) {}

  dragMoveStatus(isDrag: boolean) {
    this.isDragDisabled = isDrag;
  }

  dragMove(dragHandle: HTMLElement, e: CdkDragMove<any>) {
    const isBracketName = dragHandle.classList.contains('bracket-name');
    if (isBracketName) {
      this.isDragDisabled = true;
    } else {
      this.isDragDisabled = false;

      this.ngZone.runOutsideAngular(() => {
        this.resize(dragHandle, this.resizeBoxElement, e);
      });
    }
  }

  resize(dragHandle: HTMLElement, target: HTMLElement, event) {
    let width: number,
      height: number = 0;

    this.isFromResize = true;
    const dragRect = dragHandle.getBoundingClientRect();
    const targetRect = target.getBoundingClientRect();
    width = dragRect.left - targetRect.left + dragRect.width;
    height = dragRect.top - targetRect.top + dragRect.height;

    target.style.width = width + 'px';
    target.style.height = height + 'px';
    this.bracket.bottomSubSourceId;
    let data = {
      bracketId: this.bracket.id,
      topSource: this.bracket.topSource,
      bottomSource: this.bracket.bottomSource,
      topSubSourceId: this.topSubSelected?.refId,
      bottomSubSourceId: this.bottomSubSelected?.refId,
      height: height,
      width: width,
      x: this.bracket.x1,
      y: this.bracket.y1,
      bracketType: this.bracket.bracketType,
      name: this.bracket.name,
    };
    this.tempUndoData = data;
    this.setAllHandleTransform();

    // this.saveBracketDimension$.next(data);
  }

  openEditBracket(bracketType: string): void {
    console.log('opening bracket tye???/? ', bracketType);

    this.bracketTypes = [
      { name: 'Right Facing', value: 'RIGHT_FACING' },
      { name: 'Left Facing', value: 'LEFT_FACING' },
      { name: 'Middle', value: 'MIDDLE' },
      { name: 'Bracket Winner', value: 'BRACKET_WINNER' },
      { name: 'Box', value: 'BOX' },
      { name: 'Text', value: 'TEXT' },
      { name: 'Header', value: 'HEADER' },
    ];
    this.bracketTypes = this.bracketTypes.filter((v) => v.value != bracketType);
    this.isOpenEditBracket = true;
  }

  onSubmitEditBracket() {
    let data = {
      id: this.bracket.id,
      height: this.bracketHeight,
      width: this.bracketWidth,
      x: this.bracket.x1,
      y: this.bracket.y1,
      bracketType: this.bracket.bracketType,
      name: this.bracket.name,
    };
    this.bracketService.editBracketType$.next(data);
    this.isOpenEditBracket = false;
  }

  triggerSetBracketData(isFirst: boolean) {
    // if not the First Call to this method
    if (!isFirst) {
      let data = {
        id: this.bracket.id,
        bracketSet: this.bracketSet,
        name: this.bracket.name,
        bracketType: this.bracket.bracketType,
        topSource: this.topSelectedOption,
        bottomSource: this.bottomSelectedOption,
        topSubSourceId: this.topSubSelected?.refId,
        bottomSubSourceId: this.bottomSubSelected?.refId,
        x1: this.bracket.x1,
        y1: this.bracket.y1,
        x2: this.bracket.x2,
        y2: this.bracket.y2,
        version: this.version == -1 ? this.bracket.version : this.version,
      };
      this.bracketService.setBrackets$.next(data);
    }
  }

  triggerUndoData() {
    if (!this.isFromUndo) {
      let undoData = {
        bracketId: this.bracket.id,
        height: this.bracketHeight,
        width: this.bracketWidth,
        x: this.bracket.x1,
        y: this.bracket.y1,
        topSource: this.bracket.topSource,
        bottomSource: this.bracket.bottomSource,
        topSubSourceId: this.topSubSelected?.refId,
        bottomSubSourceId: this.bottomSubSelected?.refId,
        bracketType: this.bracket.bracketType,
        bracketName: this.bracket.name,
        br: this.bracket,
      };

      this.bracketService.undoData$.next(undoData);
    }
    this.isFromUndo = false;
  }

  updateGameSubOptions(bracket: any) {
    if (bracket) {
      let winnerOption = this.mainOptions?.find((option) => {
        return option.refType === 'WINNER';
      });
      if (winnerOption) {
        let gameWinner = winnerOption.subSources.find((option) => {
          return option.refId === bracket.gameId;
        });
        if (gameWinner) {
          gameWinner.description = bracket.name;
        }
      }

      let loserOption = this.mainOptions?.find((option) => {
        return option.refType === 'LOSER';
      });
      if (loserOption) {
        let gameLoser = loserOption.subSources.find((option) => {
          return option.refId === bracket.gameId;
        });
        if (gameLoser) {
          gameLoser.description = bracket.name;
        }
      }
    }
  }

  calculateHeight(y1: number, y2: number): number {
    let y = y2 - y1;
    return Math.round(y);
  }

  calculateWidth(x3: number, x1: number): number {
    return Math.round(x3 - x1);
  }
  onChangeText(event: any) {
    // Handle changes to the text input
    console.log(event.target.value);
  }
}
